import React from "react";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { route } from "./static/route";
import LoginPage from "./pages/login";
import NotFoundPage from "./pages/notFound";
import DashboardPage from "./pages/dashboard";
import PrivateRoute from "./components/route/PrivateRoute";
import ItemsPage from "./pages/items";
import ProcessesPage from "./pages/processes";
import PartiesPage from "./pages/parties";
import OrdersPage from "./pages/orders";
import PaymentsPage from "./pages/payments";
import UsersPage from "./pages/users";
import EmployeesPage from "./pages/employees";
import SalariesPage from "./pages/salaries";
import AccountTypesPage from "./pages/accountTypes";
import AccountsPage from "./pages/accounts";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path={route.login} element={<LoginPage />} />

        <Route
          path={route.dashboard}
          element={
            <PrivateRoute element={<DashboardPage />} path={route.login} />
          }
        />

        <Route
          path={route.items}
          element={<PrivateRoute element={<ItemsPage />} path={route.login} />}
        />

        <Route
          path={route.processes}
          element={
            <PrivateRoute element={<ProcessesPage />} path={route.login} />
          }
        />

        <Route
          path={route.parties}
          element={
            <PrivateRoute element={<PartiesPage />} path={route.login} />
          }
        />

        <Route
          path={route.orders}
          element={<PrivateRoute element={<OrdersPage />} path={route.login} />}
        />

        <Route
          path={route.payments}
          element={
            <PrivateRoute element={<PaymentsPage />} path={route.login} />
          }
        />

        <Route
          path={route.users}
          element={<PrivateRoute element={<UsersPage />} path={route.login} />}
        />

        <Route
          path={route.employees}
          element={
            <PrivateRoute element={<EmployeesPage />} path={route.login} />
          }
        />

        <Route
          path={route.salaries}
          element={
            <PrivateRoute element={<SalariesPage />} path={route.login} />
          }
        />

        <Route
          path={route.accountTypes}
          element={
            <PrivateRoute element={<AccountTypesPage />} path={route.login} />
          }
        />

        <Route
          path={route.accounts}
          element={
            <PrivateRoute element={<AccountsPage />} path={route.login} />
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthProvider>
  );
};
export default App;
