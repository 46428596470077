import React, { useState } from "react";
import styles from "./AccountList.module.scss";

import moment from "moment";
import { useAppDispatch } from "../../app/hooks";
import {
  setEditAccount,
  toggleAccountModal,
} from "../../features/accounts/accountsSlice";
import useConfirmPopup from "../../context/useConfirmPopup";
import { AccountsProps } from "../../types/accounts";

type Props = {
  accounts: AccountsProps[];
  pageNumber: number;
  pageSize: number;
  isLoading: boolean;
  handleDeleteAccount: (id: number, password: string) => void;
};

const AccountList = ({
  accounts,
  pageNumber,
  pageSize,
  isLoading,
  handleDeleteAccount,
}: Props) => {
  const dispatch = useAppDispatch();
  const confirmDelete = useConfirmPopup();

  const [isActionLoading, setIsActionLoading] = useState<number>(0);

  const handleEditAccount = async (account: AccountsProps) => {
    await dispatch(setEditAccount(account));
    dispatch(toggleAccountModal(account?.status === 0 ? 1 : 2));
  };

  const handleDeleteRow = async (id: number) => {
    const response = await confirmDelete(
      `તમે આને પાછું ફેરવી શકશો નહીં!`,
      `શું તમે ખાતરી આપો છો?`,
      "હા, તેને કાઢી નાખો!",
      true
    );

    if (response.confirmed) {
      setIsActionLoading(id);
      await handleDeleteAccount(id, response.password);
      setIsActionLoading(0);
    }
  };

  return (
    <div className={styles.AccountList}>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    એકાઉન્ટ ટાઈપ/પાર્ટી
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    સ્ટેટ્સ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-base text-primary-1 font-bold uppercase tracking-wider text-right"
                  >
                    આવક/જાવક
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    નોંધ/ડિસ્ક્રિપ્શન
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    તારીખ
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-base text-primary-1 font-bold uppercase tracking-wider"
                  >
                    એકશન્સ બટન
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={7}
                      className="text-primary-2 font-bold text-xl text-center py-2"
                    >
                      રાહ જુઓ...
                    </td>
                  </tr>
                ) : (
                  <>
                    {accounts.length > 0 ? (
                      <>
                        {accounts.map((account, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {(pageNumber - 1) * pageSize + index + 1}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {account.account_type_id &&
                              account.payment_id === null &&
                              account.salary_id === null ? (
                                <>
                                  {account?.account_type?.name || "-"}
                                  {account?.account_type?.phone
                                    ? " - " + account?.account_type?.phone || ""
                                    : ""}
                                </>
                              ) : account?.payment_id &&
                                account.payment_id !== null ? (
                                <>
                                  પેમેન્ટ ID: {account?.payment_id}
                                  <br />
                                  પાર્ટી:{" "}
                                  {account?.payment?.party?.firm_name || ""}
                                  {account?.payment?.party?.phone
                                    ? " - " + account?.payment?.party?.phone
                                    : ""}
                                </>
                              ) : account?.salary_id &&
                                account.salary_id !== null ? (
                                <>
                                  પગાર ID: {account?.salary_id}
                                  <br />
                                  કામદાર:{" "}
                                  {account?.salary?.employee?.name || ""}
                                  {account?.salary?.employee?.phone
                                    ? " - " + account?.salary?.employee?.phone
                                    : ""}
                                </>
                              ) : (
                                "-"
                              )}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {account?.status === 0 ? "આવક" : "જાવક"}
                            </td>

                            <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-900">
                              {account?.amount?.toFixed(2) || "0.00"}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {account?.description || "-"}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {moment(account.created_at).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {isActionLoading === account.id ? (
                                <p className="text-primary-2 font-bold text-right text-lg">
                                  રાહ જુઓ...
                                </p>
                              ) : account.account_type_id &&
                                account.account_type_id !== null ? (
                                <>
                                  <button
                                    onClick={() => handleEditAccount(account)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => handleDeleteRow(account.id)}
                                    className="text-red-600 hover:text-red-900 ml-4"
                                  >
                                    Delete
                                  </button>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={7}
                          className="text-primary-2 font-bold text-xl text-center py-2"
                        >
                          એકાઉન્ટ ની વિગતો મળી નથી
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountList;
