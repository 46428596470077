import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";
import { AccountTypesProps } from "../../types/accountTypes";

export interface AccountTypesState {
  accountTypes: AccountTypesProps[];
  totalAccountTypes: number;
  isAccountTypeModalOpen: boolean;
  editAccountType: AccountTypesProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: AccountTypesState = {
  accountTypes: [],
  totalAccountTypes: 0,
  isAccountTypeModalOpen: false,
  editAccountType: null,
  loading: false,
  error: null,
};

type FetchAccountTypesListDataProps = {
  page: number;
  size?: number;
  fromDate?: string | null;
  toDate?: string | null;
  search?: string | null;
};

export const fetchAccountTypesListData = createAsyncThunk(
  "fetchAccountTypesListData",
  async (req: FetchAccountTypesListDataProps) => {
    let response: any;
    await GET(`/v1/admin/account-types`, { ...req })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const accountTypesSlice = createSlice({
  name: "accountTypes",
  initialState,
  reducers: {
    toggleAccountTypeModal: (state, action: PayloadAction<boolean>) => {
      state.isAccountTypeModalOpen = action.payload;
    },
    setEditAccountType: (
      state,
      action: PayloadAction<AccountTypesProps | null>
    ) => {
      state.editAccountType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountTypesListData.pending, (state, action: any) => {
      state.accountTypes = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      fetchAccountTypesListData.fulfilled,
      (state, action: any) => {
        state.loading = false;
        if (action.payload.data.status === "success") {
          state.accountTypes = action.payload.data.data.records;
          state.totalAccountTypes = action.payload.data.data.total_records;
          state.error = null;
        } else {
          state.accountTypes = [];
          state.error =
            action?.payload?.data?.message ||
            "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
        }
      }
    );
    builder.addCase(
      fetchAccountTypesListData.rejected,
      (state, action: any) => {
        state.accountTypes = [];
        state.loading = false;
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    );
  },
});

export const { toggleAccountTypeModal, setEditAccountType } =
  accountTypesSlice.actions;

export const getAccountTypes = (state: RootState) =>
  state.accountTypes.accountTypes;

export const getTotalAccountTypes = (state: RootState) =>
  state.accountTypes.totalAccountTypes;

export const getIsAccountTypeModalOpen = (state: RootState) =>
  state.accountTypes.isAccountTypeModalOpen;

export const getEditAccountType = (state: RootState) =>
  state.accountTypes.editAccountType;

export default accountTypesSlice.reducer;
