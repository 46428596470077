import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";

import styles from "./DashboardPage.module.scss";
import { POST } from "../../services/axios.service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { route } from "../../static/route";
import { getLoggingUser } from "../../features/loggingUser/loggingUserSlice";
import { useAppSelector } from "../../app/hooks";

const DashboardPage = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const loggingUser = useAppSelector(getLoggingUser);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<string | null>(null);

  const handleLogout = async () => {
    setIsLoading(true);
    setRequestError(null);

    try {
      await POST("/v1/logout", {})
        .then(async (response: any) => {
          if (response.data.status === "success") {
            await localStorage.removeItem("authToken");
            await setIsAuthenticated(false);

            navigate(route.login);
          } else {
            setRequestError(response.data.message);
          }
        })
        .catch((error: any) => {
          setRequestError(error.data.message);
        });
    } catch (error) {
      setRequestError("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.");
    }

    setIsLoading(false);
  };

  const hanldeGoToHome = () => {
    navigate(route.dashboard);
  };

  return (
    <Layout>
      <div className={styles.DashboardPage}>
        <div className="logoSection" onClick={hanldeGoToHome}>
          <h1 className="text-5xl font-bold text-white lg:text-3xl">
            કનૈયા પ્લેટિંગ
          </h1>

          <hr className="w-[180px] border-white border-[4px] rounded-[4px] mx-auto mt-4 lg:mx-0 lg:w-24 lg:border-[2px] lg:mt-2" />
        </div>

        <div className="loginInfoSection">
          <h1 className="text-5xl font-bold text-white lg:text-2xl lg:text-primary-1">
            {loggingUser?.name || ""}
          </h1>

          <p className="mt-4 text-white font-semibold text-xl lg:text-lg lg:mt-1 lg:text-primary-2">
            +91 {loggingUser?.phone || ""}
          </p>
        </div>

        <div className="mt-[70px] px-10 py-5">
          <div className="flex flex-col">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 justify-between">
              <div
                onClick={() => navigate(route.orders)}
                className="cursor-pointer bg-primary-2 flex shadow text-center rounded-lg bg-opacity-50 h-[150px]"
              >
                <p className="text-primary-1 m-auto font-bold text-lg">
                  કામ ની
                  <br />
                  વિગતો
                </p>
              </div>

              <div
                onClick={() => navigate(route.parties)}
                className="cursor-pointer bg-primary-2 flex shadow text-center rounded-lg bg-opacity-50 h-[150px]"
              >
                <p className="text-primary-1 m-auto font-bold text-lg">
                  પાર્ટી ની
                  <br />
                  વિગતો
                </p>
              </div>

              <div
                onClick={() => navigate(route.items)}
                className="cursor-pointer bg-primary-2 flex shadow text-center rounded-lg bg-opacity-50 h-[150px]"
              >
                <p className="text-primary-1 m-auto font-bold text-lg">
                  આઈટમ ની
                  <br />
                  વિગતો
                </p>
              </div>

              <div
                onClick={() => navigate(route.processes)}
                className="cursor-pointer bg-primary-2 flex shadow text-center rounded-lg bg-opacity-50 h-[150px]"
              >
                <p className="text-primary-1 m-auto font-bold text-lg">
                  પ્રોસેસ ની
                  <br />
                  વિગતો
                </p>
              </div>

              {loggingUser && loggingUser?.roles?.includes("Super Admin") && (
                <div
                  onClick={() => navigate(route.users)}
                  className="cursor-pointer bg-primary-2 flex shadow text-center rounded-lg bg-opacity-50 h-[150px]"
                >
                  <p className="text-primary-1 m-auto font-bold text-lg">
                    કામદાર ની
                    <br />
                    વિગતો
                  </p>
                </div>
              )}

              <div
                onClick={() => navigate(route.employees)}
                className="cursor-pointer bg-primary-2 flex shadow text-center rounded-lg bg-opacity-50 h-[150px]"
              >
                <p className="text-primary-1 m-auto font-bold text-lg">
                  હાજરી ની
                  <br />
                  વિગતો
                </p>
              </div>

              <div
                onClick={() => navigate(route.accountTypes)}
                className="cursor-pointer bg-primary-2 flex shadow text-center rounded-lg bg-opacity-50 h-[150px]"
              >
                <p className="text-primary-1 m-auto font-bold text-lg">
                  એકાઉન્ટ ટાઈપ
                  <br />
                  ની વિગતો
                </p>
              </div>

              <div
                onClick={() => navigate(route.accounts)}
                className="cursor-pointer bg-primary-2 flex shadow text-center rounded-lg bg-opacity-50 h-[150px]"
              >
                <p className="text-primary-1 m-auto font-bold text-lg">
                  એકાઉન્ટ ની
                  <br />
                  વિગતો
                </p>
              </div>
            </div>

            {requestError !== null && (
              <span className="text-red-500 text-center mt-8">
                {requestError}
              </span>
            )}

            <button
              className="mx-auto mt-8 shadow rounded-3xl text-3xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
              type="button"
              disabled={isLoading}
              onClick={handleLogout}
            >
              {isLoading ? "રાહ જુઓ..." : "લોગૉઉટ કરો"}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default DashboardPage;
