import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getEditAccount,
  getIsAccountModalOpen,
  toggleAccountModal,
  getAccountTypes,
} from "../../features/accounts/accountsSlice";

import styles from "./AccountFormModal.module.scss";
import { IFormInput, OptionsProps } from "../../types/accounts";
import Select from "react-select";
import { selectStyles } from "../../static/styles";

type Props = {
  handleSaveAccount: (data: IFormInput) => void;
};

const AccountFormModal = ({ handleSaveAccount }: Props) => {
  const dispatch = useAppDispatch();
  const editAccount = useAppSelector(getEditAccount);
  const isAccountModalOpen = useAppSelector(getIsAccountModalOpen);
  const accountTypes = useAppSelector(getAccountTypes);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountTypeOption, setAccountTypeOption] = useState<OptionsProps[]>(
    []
  );
  const [statusOption] = useState<OptionsProps[]>([
    {
      value: 0,
      label: "આવક",
    },
    {
      value: 1,
      label: "જાવક",
    },
  ]);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      account_type_id: 0,
      amount: 0,
      status: null,
      description: "",
    },
  });

  const handleOnSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    setIsLoading(true);
    await handleSaveAccount(data);
    handleCloseFormModal();
    setIsLoading(false);
  };

  const handleCloseFormModal = () => {
    reset();
    dispatch(toggleAccountModal(0));
  };

  useEffect(() => {
    const options =
      accountTypes?.map((accountType) => ({
        value: accountType.id,
        label: accountType.name,
      })) ?? [];
    setAccountTypeOption(options);
  }, [accountTypes]);

  useEffect(() => {
    if (editAccount && editAccount.id) {
      reset({
        account_type_id: editAccount.account_type_id,
        amount: editAccount.amount,
        status: editAccount.status,
        description: editAccount.description,
      });
    } else {
      reset({
        account_type_id: 0,
        amount: 0,
        status: isAccountModalOpen === 1 ? 0 : 1,
        description: "",
      });
    }
  }, [editAccount, isAccountModalOpen]);

  return (
    <>
      {isAccountModalOpen > 0 && (
        <div className={styles.AccountFormModal}>
          <div className="relative h-[100vh] w-[100vw] overflow-y-scroll lg:overflow-auto top-0 lg:top-10 lg:mx-auto p-5 border lg:w-96 lg:h-fit shadow-lg lg:rounded-md bg-white">
            <div className="mt-3">
              <h3 className="text-2xl text-center font-bold leading-6 text-primary-1">
                {isAccountModalOpen === 1 ? "આવક" : "જાવક"} ની માહિતી
              </h3>
              <div className="mt-2 py-3">
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                  <div className="grid gap-4">
                    <div className="mt-1">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                        <Controller
                          {...register("account_type_id", {
                            required: true,
                            min: 1,
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={
                                accountTypeOption.find(
                                  (val) => val.value === field.value
                                ) || null
                              }
                              className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                              styles={selectStyles}
                              placeholder={"એકાઉન્ટ ટાઈપ દાખલ કરો"}
                              options={accountTypeOption}
                              onChange={(val: any) =>
                                setValue("account_type_id", val?.value)
                              }
                            />
                          )}
                        />
                      </div>

                      {errors?.account_type_id && (
                        <span className="text-red-500">
                          એકાઉન્ટ ટાઈપ સિલેક્ટ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-1 hidden">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                        <Controller
                          {...register("status", { required: true })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={
                                statusOption.find(
                                  (val) => val.value === field.value
                                ) || null
                              }
                              className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                              styles={selectStyles}
                              placeholder={"સ્ટેટ્સ નું નામ દાખલ કરો"}
                              options={statusOption}
                              onChange={(val: any) =>
                                setValue("status", val?.value)
                              }
                            />
                          )}
                        />
                      </div>

                      {errors?.status && (
                        <span className="text-red-500">
                          સ્ટેટ્સ નું નામ સિલેક્ટ કરો
                        </span>
                      )}
                    </div>

                    <div className="mt-1">
                      <label className="text-primary-1 font-bold">
                        અમાઉન્ટ :
                      </label>
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                        <input
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          type="number"
                          pattern="-?[0-9]*"
                          step={"any"}
                          placeholder="અમાઉન્ટ દાખલ કરો"
                          {...register("amount", { required: true })}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                      </div>
                      {errors.amount && errors.amount.type === "required" && (
                        <span className="text-red-500">અમાઉન્ટ દાખલ કરો</span>
                      )}
                    </div>

                    <div className="mt-3">
                      <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-28 w-full relative rounded">
                        <textarea
                          rows={5}
                          className="text-primary-1 focus:outline-none px-3 py-1 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          placeholder="ડિસ્ક્રિપ્શન દાખલ કરો"
                          {...register("description", { required: false })}
                        />
                      </div>
                      {errors.description &&
                        errors.description.type === "required" && (
                          <span className="text-red-500">
                            ડિસ્ક્રિપ્શન દાખલ કરો
                          </span>
                        )}
                    </div>

                    <div className="mt-3 text-center">
                      <button
                        className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "રાહ જુઓ..." : "આગળ વધો"}
                      </button>
                    </div>

                    <div className="mt-1 text-center">
                      <button
                        className="mx-auto shadow disabled:opacity-50 rounded-3xl text-3xl lg:text-xl text-white bg-gradient-to-r from-primary-1 to-primary-2 font-bold px-10 py-2"
                        type="button"
                        onClick={handleCloseFormModal}
                        disabled={isLoading}
                      >
                        બંધ કરો
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountFormModal;
