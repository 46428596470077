import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { FaArrowLeft, FaPlus, FaSearch, FaCalendarAlt } from "react-icons/fa";

import styles from "./AccountsPage.module.scss";
import { useNavigate } from "react-router-dom";
import { route } from "../../static/route";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchAccountsListData,
  getEditAccount,
  getAccounts,
  getTotalAccounts,
  setEditAccount,
  toggleAccountModal,
  fetchAccountTypesList,
  fetchPartiesList,
  getParties,
  getAccountTypes,
  getTotalCredit,
  getTotalDebit,
} from "../../features/accounts/accountsSlice";
import Pagination from "../../components/pagination";
import { IFormInput, OptionsProps } from "../../types/accounts";
import { selectStyles, toastStyles } from "../../static/styles";
import { toast } from "react-toastify";
import { DELETE, GET, POST, PUT } from "../../services/axios.service";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import AccountFormModal from "../../components/accounts/AccountFormModal";
import AccountList from "../../components/accounts/AccountList";

const AccountsPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(getAccounts);
  const totalAccounts = useAppSelector(getTotalAccounts);
  const editAccount = useAppSelector(getEditAccount);
  const parties = useAppSelector(getParties);
  const accountTypes = useAppSelector(getAccountTypes);
  const totalCredit = useAppSelector(getTotalCredit);
  const totalDebit = useAppSelector(getTotalDebit);

  const [partyOption, setPartyOption] = useState<OptionsProps[]>([]);
  const [accountTypeOption, setAccountTypeOption] = useState<OptionsProps[]>(
    []
  );
  const [statusOption] = useState<OptionsProps[]>([
    {
      value: 1,
      label: "આવક",
    },
    {
      value: 2,
      label: "જાવક",
    },
  ]);

  interface FilterFormInput {
    name: string;
    fromDate: string;
    toDate: string;
    party_id: number;
    account_type_id: number;
    status: number;
  }

  const { handleSubmit, register, reset, getValues, control, setValue } =
    useForm<FilterFormInput>();

  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(50);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState(false);

  const hanldeGoToHome = () => {
    navigate(route.dashboard);
  };

  const paginate = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handleShowAccountForm = async (type: number) => {
    await dispatch(setEditAccount(null));
    dispatch(toggleAccountModal(type));
  };

  const handleSaveAccount = async (data: IFormInput) => {
    try {
      if (editAccount && editAccount.id) {
        await PUT(`/v1/admin/accounts/${editAccount.id}`, {
          amount: data.amount,
          account_type_id: data.account_type_id,
          status: data.status,
          description: data.description,
        })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              await dispatch(setEditAccount(null));

              if (page === 1) {
                fetchAccountsList(page, pageSize);
              } else {
                setPage(1);
              }

              toast.success(response.data.message, {
                ...(toastStyles as any),
              });
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      } else {
        await POST("/v1/admin/accounts", {
          amount: data.amount,
          account_type_id: data.account_type_id,
          status: data.status,
          description: data.description,
        })
          .then(async (response: any) => {
            if (response.data.status === "success") {
              if (page === 1) {
                fetchAccountsList(page, pageSize);
              } else {
                setPage(1);
              }

              toast.success(response.data.message, {
                ...(toastStyles as any),
              });
            } else {
              toast.error(response.data.message, {
                ...(toastStyles as any),
              });
            }
          })
          .catch((error: any) => {
            toast.error(error.data.message, {
              ...(toastStyles as any),
            });
          });
      }
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const handleDeleteAccount = async (id: number, password: string) => {
    try {
      await DELETE(`/v1/admin/accounts/${id}`, { password: password })
        .then(async (response: any) => {
          if (response.data.status === "success") {
            const pageNumber = accounts.length > 1 ? page : page - 1;
            if (page === pageNumber) {
              fetchAccountsList(page, pageSize);
            } else {
              setPage(pageNumber);
            }

            toast.success(response.data.message, {
              ...(toastStyles as any),
            });
          } else {
            toast.error(response.data.message, {
              ...(toastStyles as any),
            });
          }
        })
        .catch((error: any) => {
          toast.error(error.data.message, {
            ...(toastStyles as any),
          });
        });
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const handleOnFilterSubmit = async () => {
    fetchAccountsList(page, pageSize);
  };

  const handleClearFilter = () => {
    reset();
    fetchAccountsList(page, pageSize);
  };

  const handleExportReport = async () => {
    try {
      const data = getValues();

      await GET(`/v1/admin/get-accounts-report`, {
        search: data?.name || null,
        fromDate: data?.fromDate || null,
        toDate: data?.toDate || null,
        party_id: data?.party_id || null,
        account_type_id: data?.account_type_id || null,
        status: data?.status || null,
      })
        .then(async (response: any) => {
          if (response.data.status === "success") {
            toast.success(response.data.message, {
              ...(toastStyles as any),
            });

            const downloadLink = response.data.data.download_link;
            try {
              const newWindow = window.open(downloadLink, "_blank");
              if (
                !newWindow ||
                newWindow.closed ||
                typeof newWindow.closed == "undefined"
              ) {
                throw new Error("Pop-up blocked");
              }
            } catch (e) {
              window.location.href = downloadLink;
            }
          } else {
            toast.error(response.data.message, {
              ...(toastStyles as any),
            });
          }
        })
        .catch((error: any) => {
          toast.error(error.data.message, {
            ...(toastStyles as any),
          });
        });
    } catch (error) {
      toast.error("કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.", {
        ...(toastStyles as any),
      });
    }
  };

  const fetchAccountsList = async (page: number, size: number) => {
    const data = getValues();

    setIsLoading(true);

    dispatch(
      fetchAccountsListData({
        page: page,
        size: size,
        search: data?.name || null,
        fromDate: data?.fromDate || null,
        toDate: data?.toDate || null,
        party_id: data?.party_id || null,
        account_type_id: data?.account_type_id || null,
        status: data?.status || null,
      })
    ).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const options =
      parties?.map((party) => ({ value: party.id, label: party.firm_name })) ??
      [];
    setPartyOption(options);
  }, [parties]);

  useEffect(() => {
    const options =
      accountTypes?.map((accountType) => ({
        value: accountType.id,
        label: accountType.name,
      })) ?? [];
    setAccountTypeOption(options);
  }, [accountTypes]);

  useEffect(() => {
    fetchAccountsList(page, pageSize);
  }, [page]);

  useEffect(() => {
    dispatch(fetchAccountTypesList());
    dispatch(fetchPartiesList());
  }, []);

  return (
    <Layout>
      <div className={styles.AccountsPage}>
        <div className="logoSection">
          <h1
            onClick={hanldeGoToHome}
            className="text-5xl font-bold text-white lg:text-3xl cursor-pointer lg:w-fit"
          >
            કનૈયા પ્લેટિંગ
          </h1>

          <hr className="w-[180px] border-white border-[4px] rounded-[4px] mx-auto mt-4 lg:mx-0 lg:w-24 lg:border-[2px] lg:mt-2" />
        </div>

        <div className="pageTitleSection">
          <FaArrowLeft
            onClick={hanldeGoToHome}
            className="lg:hidden absolute top-5 left-5 text-3xl text-white border-2 border-white p-1 rounded-full"
          />

          <h1 className="text-5xl mt-6 lg:mt-0 font-bold text-white lg:text-2xl lg:text-primary-1">
            એકાઉન્ટ ની વિગતો
          </h1>
        </div>

        <div className="mt-[60px] px-10 py-5">
          <div className="lg:flex justify-between gap-4 items-center mb-4">
            <div className="w-full lg:w-fit lg:mt-4">
              <div className="flex justify-between">
                <p className="text-primary-1 font-bold">ટોટલ આવક : </p>
                <p className="text-primary-1 font-bold">
                  &nbsp;&nbsp;{totalCredit.toFixed(2) || 0.0}
                </p>
              </div>

              <div className="flex justify-between">
                <p className="text-primary-1 font-bold">ટોટલ જાવક &nbsp;:</p>
                <p className="text-primary-1 font-bold">
                  &nbsp;&nbsp;{totalDebit.toFixed(2) || 0.0}
                </p>
              </div>
            </div>

            <div className="mt-4 lg:mt-0 w-full lg:w-fit flex justify-between lg:justify-end gap-4">
              <button
                className="p-2 rounded-md flex items-center bg-gradient-to-r from-primary-1 to-primary-2 text-white transition-colors duration-300"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FaSearch className="mr-2" />{" "}
                {showFilter ? "ફિલ્ટર છુપાવો" : "ફિલ્ટર બતાવો"}
              </button>
              <button
                className="bg-gradient-to-r from-primary-1 to-primary-2 text-white p-2 rounded-md flex items-center transition-colors duration-300"
                onClick={() => handleShowAccountForm(1)}
              >
                <FaPlus className="mr-2" /> આવક ઉમેરો
              </button>
              <button
                className="bg-gradient-to-r from-primary-1 to-primary-2 text-white p-2 rounded-md flex items-center transition-colors duration-300"
                onClick={() => handleShowAccountForm(2)}
              >
                <FaPlus className="mr-2" /> જાવક ઉમેરો
              </button>
            </div>
          </div>

          {showFilter && (
            <form onSubmit={handleSubmit(handleOnFilterSubmit)}>
              <div className="mb-4 bg-white shadow rounded p-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1 placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="date"
                      {...register("fromDate")}
                      placeholder="From Date"
                    />
                  </div>

                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded pr-[2px] h-[44px]">
                    <FaCalendarAlt className="text-white mx-2" />
                    <input
                      className="bg-white text-primary-1  placeholder:text-primary-2 font-semibold text-xl focus:outline-none p-2 rounded flex-grow h-[40px]"
                      type="date"
                      {...register("toDate")}
                      placeholder="To Date"
                    />
                  </div>

                  <div className="flex items-center bg-gradient-to-r from-primary-1 to-primary-2 rounded p-[2px] h-[44px]">
                    <input
                      {...register("name")}
                      className="text-primary-1 px-3 py-1 font-semibold text-xl placeholder:text-primary-2 w-full h-full rounded focus:outline-none"
                      placeholder="નોંધ દ્વારા શોધો"
                    />
                  </div>

                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                    <Controller
                      {...register("party_id")}
                      control={control}
                      render={({ field }) => (
                        <Select
                          isClearable
                          {...field}
                          value={
                            partyOption.find(
                              (val) => val.value === field.value
                            ) || null
                          }
                          className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          styles={selectStyles}
                          placeholder={"પાર્ટી નું નામ સિલેક્ટ કરો"}
                          options={partyOption}
                          onChange={(val: any) =>
                            setValue("party_id", val?.value)
                          }
                        />
                      )}
                    />
                  </div>

                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                    <Controller
                      {...register("account_type_id")}
                      control={control}
                      render={({ field }) => (
                        <Select
                          isClearable
                          {...field}
                          value={
                            accountTypeOption.find(
                              (val) => val.value === field.value
                            ) || null
                          }
                          className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          styles={selectStyles}
                          placeholder={"એકાઉન્ટ ટાઈપ સિલેક્ટ કરો"}
                          options={accountTypeOption}
                          onChange={(val: any) =>
                            setValue("account_type_id", val?.value)
                          }
                        />
                      )}
                    />
                  </div>

                  <div className="bg-gradient-to-r from-primary-1 to-primary-2 h-16 lg:h-12 w-full relative rounded">
                    <Controller
                      {...register("status")}
                      control={control}
                      render={({ field }) => (
                        <Select
                          isClearable
                          {...field}
                          value={
                            statusOption.find(
                              (val) => val.value === field.value
                            ) || null
                          }
                          className="text-primary-1 p-0 font-semibold text-xl placeholder:text-primary-2 absolute top-[2px] left-[2px] w-[calc(100%-4px)] h-[calc(100%-4px)] rounded"
                          styles={selectStyles}
                          placeholder={"સ્ટેટ્સ નું નામ સિલેક્ટ કરો"}
                          options={statusOption}
                          onChange={(val: any) =>
                            setValue("status", val?.value)
                          }
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex gap-4 justify-end mt-4">
                  <button
                    onClick={handleClearFilter}
                    type="button"
                    className="bg-primary-1 hover:bg-primary-2 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    હટાઓ
                  </button>

                  <button
                    onClick={handleExportReport}
                    type="button"
                    className="bg-primary-1 hover:bg-primary-2 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    રિપોર્ટ બનાવો
                  </button>

                  <button
                    type="submit"
                    className="bg-primary-1 hover:bg-primary-2 text-white px-4 py-2 rounded-md transition duration-300 ease-in-out"
                  >
                    ફિલ્ટર લાગુ કરો
                  </button>
                </div>
              </div>
            </form>
          )}

          <AccountList
            accounts={accounts}
            pageNumber={page}
            pageSize={pageSize}
            isLoading={isLoading}
            handleDeleteAccount={handleDeleteAccount}
          />

          <Pagination
            itemsPerPage={pageSize}
            totalItems={totalAccounts}
            paginate={paginate}
            currentPage={page}
          />
        </div>

        <AccountFormModal handleSaveAccount={handleSaveAccount} />
      </div>
    </Layout>
  );
};
export default AccountsPage;
