export const route = {
  home: "/",
  login: "/",
  dashboard: "/dashboard",
  items: "/items",
  processes: "/processes",
  parties: "/parties",
  orders: "/orders",
  payments: "/payments/:id",
  users: "/users",
  employees: "/employees",
  salaries: "/salaries/:id",
  accountTypes: "/account-types",
  accounts: "/accounts",
};
