import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET } from "../../services/axios.service";
import { RootState } from "../../app/store";
import { AccountsProps } from "../../types/accounts";
import { PartiesProps } from "../../types/parties";
import { AccountTypesProps } from "../../types/accountTypes";

export interface AccountsState {
  accounts: AccountsProps[];
  accountTypes: AccountTypesProps[];
  parties: PartiesProps[];
  totalAccounts: number;
  totalCredit: number;
  totalDebit: number;
  isAccountModalOpen: number;
  editAccount: AccountsProps | null;
  loading: boolean;
  error: string | null;
}

const initialState: AccountsState = {
  accounts: [],
  accountTypes: [],
  parties: [],
  totalAccounts: 0,
  totalCredit: 0,
  totalDebit: 0,
  isAccountModalOpen: 0,
  editAccount: null,
  loading: false,
  error: null,
};

type FetchAccountsListDataProps = {
  page: number;
  size?: number;
  fromDate?: string | null;
  toDate?: string | null;
  search?: string | null;
  party_id?: number | null;
  account_type_id?: number | null;
  status?: number | null;
};

export const fetchAccountsListData = createAsyncThunk(
  "fetchAccountsListData",
  async (req: FetchAccountsListDataProps) => {
    let response: any;
    await GET(`/v1/admin/accounts`, { ...req })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const fetchPartiesList = createAsyncThunk(
  "fetchPartiesListForAccountFilter",
  async () => {
    let response: any;
    await GET(`/v1/admin/parties`, { all: true })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const fetchAccountTypesList = createAsyncThunk(
  "fetchAccountTypesListForAccountFilter",
  async () => {
    let response: any;
    await GET(`/v1/admin/account-types`, { all: true })
      .then((res: any) => {
        response = res;
      })
      .catch((err) => {
        response = err;
      });
    return response;
  }
);

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    toggleAccountModal: (state, action: PayloadAction<number>) => {
      state.isAccountModalOpen = action.payload;
    },
    setEditAccount: (state, action: PayloadAction<AccountsProps | null>) => {
      state.editAccount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountsListData.pending, (state, action: any) => {
      state.accounts = [];
      state.totalCredit = 0;
      state.totalDebit = 0;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAccountsListData.fulfilled, (state, action: any) => {
      state.loading = false;
      if (action.payload.data.status === "success") {
        state.accounts = action.payload.data.data.records;
        state.totalAccounts = action.payload.data.data.total_records;
        state.totalCredit = action.payload.data.data.total_credit;
        state.totalDebit = action.payload.data.data.total_debit;
        state.error = null;
      } else {
        state.accounts = [];
        state.totalCredit = 0;
        state.totalDebit = 0;
        state.error =
          action?.payload?.data?.message ||
          "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
      }
    });
    builder.addCase(fetchAccountsListData.rejected, (state, action: any) => {
      state.accounts = [];
      state.totalCredit = 0;
      state.totalDebit = 0;
      state.loading = false;
      state.error =
        action?.payload?.data?.message ||
        "કંઈક ખોટું ગયું, કૃપા કરીને ફરીથી પ્રયત્ન કરો.";
    });
    builder.addCase(fetchPartiesList.pending, (state, action: any) => {
      state.parties = [];
    });
    builder.addCase(fetchPartiesList.fulfilled, (state, action: any) => {
      if (action.payload.data.status === "success") {
        state.parties = action.payload.data.data.records;
      } else {
        state.parties = [];
      }
    });
    builder.addCase(fetchPartiesList.rejected, (state, action: any) => {
      state.parties = [];
    });
    builder.addCase(fetchAccountTypesList.pending, (state, action: any) => {
      state.accountTypes = [];
    });
    builder.addCase(fetchAccountTypesList.fulfilled, (state, action: any) => {
      if (action.payload.data.status === "success") {
        state.accountTypes = action.payload.data.data.records;
      } else {
        state.accountTypes = [];
      }
    });
    builder.addCase(fetchAccountTypesList.rejected, (state, action: any) => {
      state.accountTypes = [];
    });
  },
});

export const { toggleAccountModal, setEditAccount } = accountsSlice.actions;

export const getAccounts = (state: RootState) => state.accounts.accounts;

export const getTotalAccounts = (state: RootState) =>
  state.accounts.totalAccounts;

export const getIsAccountModalOpen = (state: RootState) =>
  state.accounts.isAccountModalOpen;

export const getEditAccount = (state: RootState) => state.accounts.editAccount;

export const getTotalCredit = (state: RootState) => state.accounts.totalCredit;

export const getTotalDebit = (state: RootState) => state.accounts.totalDebit;

export const getParties = (state: RootState) => state.accounts.parties;

export const getAccountTypes = (state: RootState) =>
  state.accounts.accountTypes;

export default accountsSlice.reducer;
